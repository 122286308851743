<template>
  <footer
    class="text-white site-footer bg-fr-brown-dark isolate bg-[url('/img/footer-bg.webp')] bg-cover"
    aria-labelledby="footer-heading"
  >
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
      <div class="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 xl:gap-8">
        <div
          class="grid grid-cols-1 sm:grid-cols-3 gap-8 col-span-1 sm:col-span-3"
        >
          <div class="col-span-1 sm:col-span-1">
            <div>
              <h3 class="font-light border-b theme-text-3xl italic">
                Contact Us
              </h3>
              <ul role="list" class="mt-4 grid grid-cols-1 gap-4">
                <li
                  v-for="(item, index) in navigation.collections"
                  :key="item.name"
                  :class="[
                    `footer-collection-${index}`,
                    'leading-none',
                    'text-white',
                    'theme-text-body',
                  ]"
                >
                  <NuxtLink
                    :href="item.href"
                    :class="[
                      'hover:underline',
                      'leading-none',
                      'text-white',
                      '!font-semibold',
                      'theme-text-body',
                    ]"
                  >
                    {{ item.name }}
                  </NuxtLink>
                  <span
                    v-if="item.description"
                    class="block !text-sm font-normal text-white theme-text-sm"
                  >
                    {{ item.description }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-span-1 sm:col-span-2">
            <div>
              <h3 class="font-light border-b theme-text-3xl italic font-light">
                Communities
              </h3>
              <ul
                role="list"
                class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4"
              >
                <li v-for="item in navigation.neighborhoods" :key="item.id">
                  <NuxtLink
                    :href="item.href"
                    class="hover:underline leading-none text-white !font-semibold theme-text-body"
                  >
                    {{ item.name.title
                    }}<span v-if="item.name.city">
                      in {{ item.name.city }}</span
                    >
                  </NuxtLink>
                  <br />
                  <span class="text-white theme-text-base !text-sm">
                    {{ item.subtitle }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="relative xl:-top-4 p-4 mt-8 overflow-hidden xl:mt-0 col-span-2 lg:col-span-1 bg-fr-beige rounded-2xl h-[400px]"
        >
          <div
            class="w-full overflow-hidden h-[140px] absolute top-0 inset-x-0 bg-gradient-to-b to-[#CCB59F] from-[#E1DBD4]"
          >
            <div
              class="bg-gradient-to-r from-[#A49280]/80 to-[#E1DBD4]/5 absolute top-0 right-0 bottom-3 left-0"
            ></div>
            <img
              src="/img/osc-emma.png"
              class="w-auto h-[160px] absolute -top-2 -right-4"
              alt=""
              loading="lazy"
            />
            <div class="absolute inset-5">
              <h3
                class="!font-semibold !text-2xl text-white drop-shadow-text theme-text-base mb-2 uppercase"
              >
                Questions?
              </h3>
              <h4 class="drop-shadow-text theme-text-sm font-light">
                We're here to help!
              </h4>
            </div>
          </div>
          <div
            class="w-full h-[88px] bg-white absolute bottom-0 inset-x-0"
          ></div>
          <div
            class="absolute border border-2 rounded-2xl border-white/50 inset-1"
          >
            <div
              class="z-10 mt-[170px] w-full flex justify-center flex-col gap-4 w-5/6 mx-auto"
            >
              <NuxtLink
                to="/contact-us/ask-a-question/"
                class="inline-flex items-center justify-center w-full px-3 py-2 mx-auto text-sm font-medium text-white border border-transparent theme-text-lg bg-fr-primary-med leading-4 rounded-md shadow-sm hover:bg-fr-primary-med/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fr-primary-med"
              >
                Ask a Question Now
              </NuxtLink>
              <a
                href="/contact-us/ask-a-question/#organism-2"
                class="inline-flex items-center justify-center w-full px-3 py-2 mx-auto text-sm font-medium bg-white border border-gray-300 theme-text-lg text-fr-primary-med shadow-sm leading-4 rounded-md hover:bg-fr-beige-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fr-primary-med"
              >
                Visit Us
              </a>
            </div>
            <div class="flex justify-center w-full absolute bottom-[69px]">
              <NuxtLink
                to="tel:254-217-8717"
                target="_new"
                class="inline-flex items-center border border-transparent text-sm font-medium !font-figtree !leading-4 py-1 px-6 rounded-full shadow-sm text-white bg-[#858D9B] hover:bg-[#858D9B]/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fr-primary-med"
              >
                CALL US
              </NuxtLink>
            </div>
            <div class="flex justify-center w-full absolute bottom-[30px]">
              <NuxtLink
                to="tel:254-217-8717"
                target="_new"
                class="flex gap-1 text-[#6B7280] theme-text-lg"
                ><img
                  src="/img/phone-icon.svg"
                  class="inline-block"
                  loading="lazy"
                />(254) 217-8717</NuxtLink
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 flex justify-between">
        <div class="border-t border-white h-4 flex-grow"></div>
        <div class="relative px-3 space-x-3 -top-4 w-fit">
          <NuxtLink
            v-for="item in navigation.social"
            :key="item.name"
            :href="item.href"
            target="_new"
            class=""
          >
            <span class="sr-only">{{ item.name }}</span>
            <component
              :is="item.icon"
              class="inline-block sm:w-8 sm:h-8 w-6 h-6 opacity-70"
              aria-hidden="true"
            />
          </NuxtLink>
        </div>
        <div class="border-t border-white h-4 flex-grow"></div>
      </div>
      <div
        class="flex flex-wrap items-end mt-4 gap-5 opacity-80 xl:flex-nowrap justify-center"
      >
        <p class="text-xs text-white theme-text-sm font-light">
          All plans, prices, specifications, and all other information are
          subject to change without notice. Images shown are for illustration
          purposes only and are not intended to be a part of any legal contract.
          In an effort to continuously improve product and market needs,
          Flintrock Builders reserves the right to change product offerings as
          necessary and images and data shown may not reflect the current
          offerings. Final products, prices, specifications, and other
          information for specific homes or communities can be provided by
          Flintrock Builders upon request.
          <!--<NuxtLink href="/privacyterms/" class="underline"
            >Privacy / Terms</NuxtLink
          >-->
        </p>
        <img
          srcset="/img/CTHBA-Logo.png"
          src="/img/CTHBA-Logo.png"
          class="w-[84px] h-[35px]"
          loading="lazy"
        />
        <img
          srcset="/img/taba_logo.png"
          src="/img/taba_logo.png"
          alt=""
          class="w-[85px] h-[35px]"
          loading="lazy"
        />
        <img
          srcset="/img/atab.png"
          src="/img/atab.png"
          alt=""
          class="w-[82px] h-[35px]"
          loading="lazy"
        />
        <img
          srcset="/img/HBA-austin.jpg"
          src="/img/HBA-austin.jpg"
          alt=""
          class="h-[35px]"
          loading="lazy"
        />
        <img
          srcset="/img/HoTBA.webp"
          src="/img/HoTBA.webp"
          alt=""
          class="h-[35px]"
          loading="lazy"
        />
      </div>
    </div>
  </footer>
</template>

<style>
.footer-collection-0::before {
  content: "Phone:";
  padding-right: 5px;
}

.footer-collection-1::before {
  content: "Email:";
  padding-right: 5px;
}

.footer-collection-2 {
  position: relative;
  padding-top: 40px;
  width: 90%;
}

.footer-collection-2::before {
  content: "Corporate Office";
  position: absolute;
  top: 15px;
}

@media screen and (max-width: 769px) {
  .footer-collection-2 {
    width: 100%;
  }
}
</style>

<script setup>
import { defineComponent, h } from "vue";

const props = defineProps({
  collections: Array,
  neighborhoods: Array,
});

const navigation = {
  collections: [],
  neighborhoods: [],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/Flintrockbuilders/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 512 512" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com/channel/UCOPF6894mKn88LG-TaZlCtg",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 576 512" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/flintrock-builders?original_referer=https%3A%2F%2Fwww.google.com%2F",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 448 512" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Google",
      href: "https://www.google.com/search?q=flintrock+builders&sxsrf=AJOqlzUO21m9OYNdcaVhVvbxzFm8Kph-Jw%3A1674849932122&source=hp&ei=jC7UY-rtBP2s5NoPyYiOSA&iflsig=AK50M_UAAAAAY9Q8nJfaKkeG5tnPha-tuC1R_nVx6vPq&oq=flintrockbuilder&gs_lcp=Cgdnd3Mtd2l6EAEYADIHCCMQsAIQJzINCC4QDRCvARDHARCABDIHCAAQDRCABDIHCAAQDRCABDoGCAAQHhANUABYmA1gzB5oAHAAeACAAb0CiAHzBpIBBTItMi4xmAEAoAECoAEB&sclient=gws-wiz#lrd=0x86454999fd54c36d:0x1c2da62a4968e01a,1,,,,",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 488 512" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Yelp",
      href: "https://www.yelp.com/biz/flintrock-builders-belton",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 384 512" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M42.9 240.3l99.6 48.6c19.2 9.4 16.2 37.5-4.5 42.7L30.5 358.5a22.8 22.8 0 0 1 -28.2-19.6 197.2 197.2 0 0 1 9-85.3 22.8 22.8 0 0 1 31.6-13.2zm44 239.3a199.5 199.5 0 0 0 79.4 32.1A22.8 22.8 0 0 0 192.9 490l3.9-110.8c.7-21.3-25.5-31.9-39.8-16.1l-74.2 82.4a22.8 22.8 0 0 0 4.1 34.1zm145.3-109.9l58.8 94a22.9 22.9 0 0 0 34 5.5 198.4 198.4 0 0 0 52.7-67.6A23 23 0 0 0 364.2 370l-105.4-34.3c-20.3-6.5-37.8 15.8-26.5 33.9zm148.3-132.2a197.4 197.4 0 0 0 -50.4-69.3 22.9 22.9 0 0 0 -34 4.4l-62 91.9c-11.9 17.7 4.7 40.6 25.2 34.7L366 268.6a23 23 0 0 0 14.6-31.2zM62.1 30.2a22.9 22.9 0 0 0 -9.9 32l104.1 180.4c11.7 20.2 42.6 11.9 42.6-11.4V22.9a22.7 22.7 0 0 0 -24.5-22.8 320.4 320.4 0 0 0 -112.3 30.1z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "TikTok",
      href: "https://www.tiktok.com/@flintrockbuilders",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 448 512" }, [
            h("path", {
              d: "M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z",
            }),
          ]),
      }),
    },
    {
      name: "Pinterest",
      href: "https://www.pinterest.com/flintrockbuilders/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 496 512" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M248,8C111,8,0,119.3,0,256c0,110.5,69.8,204.7,168.1,240.1c-2.3-20.4-4.4-51.8,0.9-74.2c4.8-20.5,31.1-130.7,31.1-130.7s-7.7-15.4-7.7-38.1c0-35.7,20.7-62.3,46.5-62.3c21.9,0,32.5,16.4,32.5,36c0,21.9-14,54.7-21.2,85c-6,25.4,12.8,46.1,38,46.1c45.6,0,76.2-58.8,76.2-128.5c0-53.1-35.8-92.9-101.1-92.9c-73.6,0-119.3,54.9-119.3,116.2c0,21.2,6.2,36.2,15.9,47.7c4.5,5.3,5.1,7.4,3.5,13.4c-1.2,4.4-3.9,15-5.1,19.2c-1.7,6.5-7,8.8-12.9,6.4c-36.1-14.8-52.9-54.5-52.9-99.3c0-73.8,62.2-162.3,185.6-162.3c99.2,0,164.4,71.8,164.4,149.1c0,101.8-56.6,177.7-140.2,177.7c-28.1,0-54.5-15.2-63.5-32.4c0,0-15.1,60.1-18.2,71.4c-5.5,20.1-16.3,40.2-26.2,55.6C194.5,507.7,220.7,512,248,512c137,0,248-111.3,248-248C496,119.3,385,8,248,8z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/flintrockbuilders_ctx/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 448 512" }, [
            h("path", {
              d: "M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z",
            }),
          ]),
      }),
    },
  ],
};

watchEffect(() => {
  props.collections.forEach((item) =>
    navigation.collections.push({
      name: item.label,
      description: item.description,
      href: item.path,
    }),
  );

  props.neighborhoods.forEach((item) =>
    navigation.neighborhoods.push({
      name: {
        title: item.label,
        city: item.connectedNode?.node?.neighborhood?.city,
      },
      href: item.connectedNode?.node?.uri
        ? item.connectedNode.node.uri
        : item.uri,
      subtitle: getNeighborhoodNavSubtitle(
        item.connectedNode?.node?.types,
        getRoundedPrice(item.connectedNode?.node?.basePrice),
        item.connectedNode?.node?.neighborhood?.status,
      ),
    }),
  );
});
</script>
